import React, { useEffect, useState } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Img from 'components/img/markdown'
import { initEmbedly } from 'services/config'
import AeditLink from 'services/link'
import PropTypes from 'prop-types'
import { MarkdownJsx } from 'services/markdown/renderer'
import { classes } from 'styleguide/packages/util'
import styles from './markdown.scss'

const Anchor = ({ toc, children, ...props }) => {
  const optionalAttrs =
    props.href?.startsWith('#') || props.href?.includes('aedit.com')
      ? {}
      : {
          rel: 'noopener noreferrer',
          target: '_blank',
        }

  const Tag = toc ? AnchorLink : 'a'

  return (
    <Tag {...props} {...optionalAttrs}>
      {children[0]}
    </Tag>
  )
}

const NestableListItem = ({ className, children }) => {
  const expandable = children.filter(child => child.type === 'ul').length > 0
  const [expanded, setExpanded] = useState(!expandable)
  const content = expanded ? children : children.filter(child => child.type != 'ul')
  const onClick = expandable ? () => setExpanded(!expanded) : () => {}

  const classNames = classes([
    className,
    {
      expandable: expandable,
      expanded: expanded,
    },
  ])

  return (
    <li className={classNames}>
      {className === 'BEURO__list-item' ? <div>{content}</div> : <>{content}</>}
      {expandable && (
        <span onClick={onClick} className="list-item-toggler">
          {expanded ? '-' : '+'}
        </span>
      )}
    </li>
  )
}

// Custom Components //
const CaptionImg = ({ url, children, ...props }) => (
  <div className="mb-2 img-caption">
    <Img className="mb-2" publicId={url} {...props} />
    {children}
  </div>
)

const Cite = ({ number, href, title, blurb }) => {
  return (
    <sup className="markdown-citation">
      <a href={href} target="_blank" rel="noopener noreferrer">
        {number}
      </a>
      <cite className="markdown-citation-info">
        <a href={href} target="_blank" rel="noopener noreferrer">
          {title}
        </a>
        <span>{blurb}</span>
      </cite>
    </sup>
  )
}

const Dialogue = ({ children }) => <div className="markdown-dialogue">{children}</div>

const Image = ({ src, ...props }) => <Img publicId={src} {...props} />

const PullQuote = ({ children, attributedTo }) => (
  <div className="mb-4 markdown-pullquote">
    <div className="mb-1">{children}</div>
    <div className="markdown-caption">{attributedTo}</div>
  </div>
)

const Question = ({ party = 'AEDIT', children }) => (
  <p className="markdown-question">
    <span className="party">{`${party}: `}</span>
    {children}
  </p>
)

const Response = ({ party, children }) => (
  <p className="markdown-response">
    <span className="party">{`${party}: `}</span>
    {children}
  </p>
)

const RelatedContent = ({ title, slug }) => {
  return (
    <div className="markdown-related">
      <div className="mb-1 related-header">Related Content</div>
      <AeditLink className="related-link" as={`/aedition/${slug}`} pathname="/aedition/[slug]">
        {title}
      </AeditLink>
    </div>
  )
}

const Markdown = ({ toc = false, content, className, ...rest }) => {
  useEffect(() => {
    initEmbedly()
  }, [])

  const classNames = classes([
    className,
    {
      '--toc': toc,
      md: true,
    },
  ])

  return (
    <>
      <div {...rest} className={classNames}>
        <MarkdownJsx
          options={{
            overrides: {
              a: {
                component: Anchor,
                props: {
                  toc,
                },
              },
              li: {
                component: NestableListItem,
                props: {
                  className: `${className?.includes('--beuro') ? 'BEURO__list-item' : ''}`,
                },
              },
              CaptionImg: {
                component: CaptionImg,
              },
              Cite: {
                component: Cite,
              },
              Dialogue: {
                component: Dialogue,
              },
              img: {
                component: Image,
              },
              PullQuote: {
                component: PullQuote,
              },
              Question: {
                component: Question,
              },
              Response: {
                component: Response,
              },
              RelatedContent: {
                component: RelatedContent,
              },
            },
          }}>
          {content}
        </MarkdownJsx>
      </div>

      <style jsx global>
        {styles}
      </style>
    </>
  )
}

Markdown.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Markdown
